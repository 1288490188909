import "./css/LuggageStoragePage.css";
import { useTranslation } from "react-i18next";
import { useContext } from 'react';
import { WindowSizeContext } from './components/WindowSizeContext';

const LuggageStoragePage = () => {

  const { t } = useTranslation();
  const isDesktop = useContext(WindowSizeContext);

  const luggmapImage = isDesktop ? process.env.REACT_APP_CONTENT_LINK + "/luggmap01.webp" : process.env.REACT_APP_CONTENT_LINK + "/luggmap01_small.webp";
  const luggEntranceImage = isDesktop ? process.env.REACT_APP_CONTENT_LINK + "/luggage_room_entrance.webp" : process.env.REACT_APP_CONTENT_LINK + "/luggage_room_entrance_small.webp";
  const luggRoomImage1 = isDesktop ? process.env.REACT_APP_CONTENT_LINK + "/luggage_room_1.webp" : process.env.REACT_APP_CONTENT_LINK + "/luggage_room_1_small.webp";
  const luggRoomImage2 = isDesktop ? process.env.REACT_APP_CONTENT_LINK + "/luggage_room_2.webp" : process.env.REACT_APP_CONTENT_LINK + "/luggage_room_2_small.webp";
  const luggRoomImage3 = isDesktop ? process.env.REACT_APP_CONTENT_LINK + "/luggage_room_3.webp" : process.env.REACT_APP_CONTENT_LINK + "/luggage_room_3_small.webp";
  const luggRoomImage4 = isDesktop ? process.env.REACT_APP_CONTENT_LINK + "/luggage_room_4.webp" : process.env.REACT_APP_CONTENT_LINK + "/luggage_room_4_small.webp";


    return (
<div>   



  <div className="lugg-container">
    <div className="luggmap-container">
      <a href="https://www.google.com/maps/place/Luggage+Room+Mad%C3%A1ch/@47.4977941,19.0566109,18.13z/data=!4m6!3m5!1s0x4741dde458120d1f:0x39a1870bd422f855!8m2!3d47.4977289!4d19.0577839!16s%2Fg%2F11kj9lfkw7?entry=ttu" target="_blank" rel="noopener noreferrer">
          <img src={luggmapImage} alt="Google Map of Budapest, Madách Imre út 3, 1075"  />
      </a>
    </div>
    <div className="lugg-text-container">
    <p>{t("luggstorage-main")}</p>
      <div className="centered-text">
            <p><b>{t("luggstorage-open")}</b></p>
            <p>{t("luggstorage-openings")}</p>

            <p><b>{t("luggstorage-price")}</b></p>
            <p>{t("luggstorage-pricing")}</p>

            <p><b>{t("luggstorage-payment")}</b></p>
            <p>{t("luggstorage-payment-currency")}</p>
            <p>{t("luggstorage-payment-modes")}</p>
      </div>      
            <p className="discount-text">{t("luggstorage-discount")}</p>
    </div>
  </div>

<h1>{t("luggstorage-guide-title")}</h1>

<div className="gallery">
  <div className="gallery-item">
    <div className="image">
      <img src={luggEntranceImage} alt="The Entrance" />
      </div>
      <div className="text">
        <p>{t("luggstorage-guide-entrance")}</p>
      </div>
  </div>

  <div className="gallery-item">
    <div className="image">
      <img src={luggRoomImage1} alt="First Step" />
      </div>
      <div className="text">
        <p>{t("luggstorage-guide-first")}</p>
      </div>
  </div>

  <div className="gallery-item">
    <div className="image">
      <img src={luggRoomImage2} alt="Second Step" />
      </div>
      <div className="text">
        <p>{t("luggstorage-guide-second")}</p>
      </div>
  </div>

  <div className="gallery-item">
    <div className="image">
      <img src={luggRoomImage3} alt="Third Step" />
      </div>
      <div className="text">
        <p>{t("luggstorage-guide-third")}</p>
      </div>
  </div>

  <div className="gallery-item">
    <div className="image">
      <img src={luggRoomImage4} alt="Fourth Step" />
      </div>
      <div className="text">
        <p>{t("luggstorage-guide-fourth")}</p>
      </div>
  </div>

</div>


</div>    
    );

  };
  
  export default LuggageStoragePage;
