// WindowSizeContext.js
import { createContext, useState, useEffect } from 'react';

export const WindowSizeContext = createContext();

export const WindowSizeProvider = ({ children }) => {
  //const [isDesktop, setIsDesktop] = useState(window.innerWidth > 791 && window.innerHeight > 359);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 759 && window.innerHeight > 300);

  useEffect(() => {
    const handleResize = () => {
      //setIsDesktop(window.innerWidth > 791 && window.innerHeight > 359);
      setIsDesktop(window.innerWidth > 759 && window.innerHeight > 300);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <WindowSizeContext.Provider value={isDesktop}>
      {children}
    </WindowSizeContext.Provider>
  );
};






/*biztmás. jó, de nem veszi figyelembe a magasságot, így destop lesz akkor is, ha elfordítod a mobilt
import { createContext, useState, useEffect } from 'react';

export const WindowSizeContext = createContext();

export const WindowSizeProvider = ({ children }) => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 791);
  
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 791);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <WindowSizeContext.Provider value={isDesktop}>
      {children}
    </WindowSizeContext.Provider>
  );
};
*/