
import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from "react-i18next";
import Apartment from './Apartment';
import './css/ApartmentList.css';
import Loading from './components/Loading'; 
import FilterComponent from './components/FilterComponent';

const ApartmentList = () => {
  //console.log('ApartmentList rendered');

  const [apartments, setApartments] = useState([]);
  const { i18n, t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true); // add a new state for loading


  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  
  //filter
  const [minOccupancy, setMinOccupancy] = useState("");
  const [maxOccupancy, setMaxOccupancy] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("Budapest");
  const [itemsPerPage, setItemsPerPage] = useState(10);

useEffect(() => {
  setCurrentPage(1);
}, [minOccupancy, maxOccupancy, district, itemsPerPage]);

useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); 
        const response = await fetch(`${process.env.REACT_APP_BACKEND_LINK}/querySomeApartmentInfo.php`, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            body: `lang=${i18n.language}`,
        });
        const data = await response.json();
        console.log(data);
        setApartments(data);
        setIsLoading(false); 
    };

    fetchData();
}, [i18n.language]);
    




//--- NEM tárolós verzió. ==>tesztelésre!
/*
useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); 
        const response = await fetch(`${process.env.REACT_APP_BACKEND_LINK}/querySomeApartmentInfo.php`, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            body: `lang=${i18n.language}`,
        });
        const data = await response.json();
        console.log(data);
        setApartments(data);
        setIsLoading(false); 
    };

    fetchData();
}, [i18n.language]);
*/

// --- tárolós verzió. tesztelés miatt kilőve
/*
useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); 
        const response = await fetch(`${process.env.REACT_APP_BACKEND_LINK}/querySomeApartmentInfo.php`, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            body: `lang=${i18n.language}`,
        });
        const data = await response.json();
        console.log(data);
        setApartments(data);
        setIsLoading(false); 
        sessionStorage.setItem('apartmentUnitsCached', JSON.stringify(data));
    };

    let cachedApartmentsData = JSON.parse(sessionStorage.getItem('apartmentUnitsCached'));

      if (!cachedApartmentsData) {
        fetchData();
      } else {
        setApartments(cachedApartmentsData);
      }
}, [i18n.language]);
*/

const resetFilters = () => {
  setMaxOccupancy("");
  setMinOccupancy("");
  setDistrict("");
  setCity("");
  setCurrentPage(1);
}

const memoizedApartments = useMemo(() => apartments, [apartments]);

if (isLoading) {
  return <Loading />; // return the Loading component if isLoading is true
}


  let filteredApartments = memoizedApartments.filter(apartment => {
    let includeApartment = true;

      if (maxOccupancy !== "" || minOccupancy !== "") {
        let maxOccupancyNumber = maxOccupancy !== "" ? Number(maxOccupancy) : Infinity;
        let minOccupancyNumber = minOccupancy !== "" ? Number(minOccupancy) : 0;
        includeApartment = includeApartment && Number(apartment.people) >= minOccupancyNumber && Number(apartment.people) <= maxOccupancyNumber;
      }

      if (district !== "") {
        includeApartment = includeApartment && apartment.district === district;
      }

      if (city !== "") {
        includeApartment = includeApartment && apartment.city === city;
      }

    return includeApartment;
  });


const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentItems = filteredApartments.slice(indexOfFirstItem, indexOfLastItem);

return (
  <div>
    <div className="filter">
      <FilterComponent minOccupancy={minOccupancy} 
                       setMinOccupancy={setMinOccupancy} 
                       maxOccupancy={maxOccupancy} 
                       setMaxOccupancy={setMaxOccupancy} 
                       district={district} 
                       setDistrict={setDistrict} 
                       city={city}
                       setCity={setCity}
                       itemsPerPage={itemsPerPage} 
                       setItemsPerPage={setItemsPerPage} 
                       t={t} 
                       resetFilters={resetFilters}
                       memoizedApartments={memoizedApartments}
        />
    </div>
    <div className="apartment-list-container">
      {currentItems.map((apartment, index) => (
        <Apartment key={index} apartment={apartment} />
      ))}
    </div>
    <div className="pagination">
      {Array.from({ length: Math.ceil(filteredApartments.length / itemsPerPage) }, (_, i) => (
        <button key={i} onClick={() => setCurrentPage(i + 1)}>
          {i + 1}
        </button>
      ))}
    </div>
  </div>
);


};

export default ApartmentList;