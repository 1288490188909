//forwardRef+useImperativeHandle

import React, { memo, useRef, useMemo, useContext, useState } from 'react';
import { useTranslation } from "react-i18next";
import GalleryModal from './components/GalleryModal';
import './css/Apartment.css';
import { WindowSizeContext } from './components/WindowSizeContext';

const Tooltip = ({ show, content }) => {

 // if (Object.keys(content).length === 0 ) return null;

  return (
    <div className={`tooltip ${show ? 'show' : ''}`}>
      <div className="tooltip-image">
      <img src={content.image} alt={content.alt} />
      </div>
      <div className="tooltip-text">
      <p dangerouslySetInnerHTML={{ __html: content.text }}></p>
      </div>
    </div>
  );
};





const Apartment = memo(({ apartment }) => {

  const { t } = useTranslation();
  const modalRef = useRef();
  const isDesktop = useContext(WindowSizeContext);

const [showTooltip, setShowTooltip] = useState(false);

  const timeoutId = useRef(null);

  const handleMouseEnter = () => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    timeoutId.current = setTimeout(() => {
      setShowTooltip(false);
    }, 500); // delay in ms
  };

let tooltipContent = {}


if (apartment.tooltip_text) {
  tooltipContent = {
      image: process.env.REACT_APP_CONTENT_LINK + "/" + apartment.tooltip_image,
      alt: 'Your alt text',
      text: apartment.tooltip_text,
    };
}

  const handleShowModal = () => {
    modalRef.current.openModal();
  };


const galleryLinks = useMemo(() => {
    if (!apartment.gallery) return [];
    return Array.from({ length: parseInt(apartment.gallery) }, (_, i) =>
        `${process.env.REACT_APP_CONTENT_LINK}/apartments/${apartment.short_name}/${(i + 1).toString().padStart(2, '0')}${isDesktop ? '' : '_small'}.webp`
    );
}, [apartment.gallery, apartment.short_name, isDesktop]);





return (
  apartment && (
    <div className="apartment-card" key={apartment.short_name + 'apartmentCard'}>
      <h3>{apartment.long_name}</h3>
      <div className="apartment-content">
        <div className="apartment-image" onClick={handleShowModal}>
          <img src={`${process.env.REACT_APP_CONTENT_LINK}/apartments/${apartment.short_name}/cover.webp`} alt="apartment" />
        </div>
        <div className="apartment-details">

 <p>
        <b>{t("aplist-filter-district")} </b>{apartment.district}

{apartment.tooltip_text.length !== 0 && (
  <span
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
  >
    - {t("aplist-more-info")}
  </span>
)}
      </p>
       {showTooltip && (
      <Tooltip show={showTooltip} content={tooltipContent} />
       )}



          <p><b>{t("aplist-people")} </b>{apartment.people}</p>
          <p><b>{t("aplist-beds")}</b> {apartment.beds}</p>
          <p><b>{t("aplist-floor")}</b> {apartment.floor === "0" ? t("aplist-ground-floor") : apartment.floor}</p>
          <p><b>{t("aplist-rooms")}</b> {apartment.rooms}</p>  


          {apartment.short_description && apartment.short_description.length > 0 ? (
            <>
              <p><b>{t("aplist-short-description")}</b></p>
                  <p dangerouslySetInnerHTML={{ __html: apartment.short_description }}></p>
            </>
          ) : null} 


          {apartment.links && apartment.links.length > 0 ? (
            <>
              <p><b>{t("aplist-links")}</b></p>
              {apartment.links.split(',').map(s => (
                <><a href={s} target="_blank" rel="noreferrer">{s}</a><br /></>
              ))}
            </>
          ) : null}                        

        </div>
      </div>
      <GalleryModal ref={modalRef} gallery={galleryLinks}  galIndex={"modal" + apartment.short_name}/>
    </div>
  )
);
});

export default Apartment;




//trandícionális út
/*
import React, { useState, memo, useMemo } from 'react';
import { useTranslation } from "react-i18next";
import GalleryModal from './components/GalleryModal';

const Apartment = memo(({ apartment }) => {
  console.log('Apartment card rendered ' + apartment.short_name);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const { t } = useTranslation();
  
//---->így nem fog kiszámolódni újrarendereléskor és megúsztunk egy useState-et
const galleryLinks = useMemo(() => {
  console.log('galleryLinks rendered');
        if (!apartment.gallery) return [];
        return Array.from({ length: parseInt(apartment.gallery) }, (_, i) =>
            `${process.env.REACT_APP_CONTENT_LINK}/apartments/${apartment.short_name}/${i + 1}.webp`
        );
    }, [apartment.gallery, apartment.short_name]);



  return (
    <>
           <div className="apartment-card">
             <h3>{apartment.long_name}</h3>
             <div className="apartment-content">
               <div className="apartment-image" onClick={() => setIsModalVisible(true)}>
                 <img src={`${process.env.REACT_APP_CONTENT_LINK}/apartments/${apartment.short_name}/cover.webp`} alt="apartment" />
               </div>
               <div className="apartment-details">
                 <p><b>{t("aplist-people")} </b>{apartment.people}</p>
                 <p><b>{t("aplist-beds")}</b> {apartment.beds}</p>
                 <p><b>{t("aplist-floor")}</b> {apartment.floor === "0" ? t("aplist-ground-floor") : apartment.floor}</p>
                 <p><b>{t("aplist-rooms")}</b> {apartment.rooms}</p>  
                 {apartment.links ? apartment.links.split(',').map(s=> (<><a href={s} target="_blank" rel="noreferrer">{s}</a><br/></>)) : ''}                         
               </div>
             </div>
           </div>                                   
                                          
              {isModalVisible && <GalleryModal setOpenModal={setIsModalVisible}
																							 gallery={galleryLinks}
																	/>}
    </>    
  );
 
});

export default Apartment;
*/
