import "./css/Contact.css";
import { useTranslation } from "react-i18next";

function Contact() {
    const { t } = useTranslation();

return (
<div className="containerrr">
    <div className="contact-info-container">
        <h1>Twosis Apartments</h1>
        <div className="info-row">
            <div className="info-label">{t("contacts-adress")}</div>
            <div className="info-data">1075 Budapest, Madách Imre út 3</div>
        </div>
        <div className="info-row">
            <div className="info-label">{t("contacts-numbers")}</div>
            <div className="info-data">+36 30 410 3323  <br/>
                <span>{t("contacts-numbers-note1")} </span>
                <span className="mobile-break"><br/> </span>
                <span>{t("contacts-numbers-note2")} </span>
                <span className="mobile-break"><br/> </span>
                <span>{t("contacts-numbers-note3")}</span>
            </div>
        </div>
        <div className="info-row">
            <div className="info-label">{t("contacts-email")}</div>
            <div className="info-data"><a href="mailto:twosisapartments@gmail.com">twosisapartments@gmail.com</a></div>
        </div>
        <div className="info-row">
            <div className="info-label">{t("contacts-airbnb")}</div>
            <div className="info-data">
                <a href="https://www.airbnb.com/users/show/448624363" target="blank">
                    https://www.airbnb.com/
                     <span className="mobile-break"><br/> </span>
                     users/show/448624363
                </a>
            </div>
        </div>
        <div className="info-row">
            <div className="info-label">{t("contacts-facebook")}</div>
            <div className="info-data">
                <a href="https://www.facebook.com/twosisbo/">
                    https://www.facebook.com/
                     <span className="mobile-break"><br/> </span>
                     twosisbo/
                </a>
            </div>
        </div>
    </div>
</div>
    );   
}
  
export default Contact;