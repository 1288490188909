
/*
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import NavBar from "./pages/components/NavBar";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import './i18n';
import { WindowSizeProvider } from './pages/components/WindowSizeContext';
import React, { Suspense, lazy, useEffect } from 'react';
import './App.css';
import Loading from './pages/components/Loading';

export const pages = {
  '/': {
    Component: lazy(() => import("./pages/Home")),
    preload: () => import("./pages/Home"),
  },
  '/luggagestorage': {
    Component: lazy(() => import("./pages/LuggageStoragePage")),
    preload: () => import("./pages/LuggageStoragePage"),
  },
  '/apartmentlist': {
    Component: lazy(() => import("./pages/ApartmentList")),
    preload: () => import("./pages/ApartmentList"),
  },
  '/about': {
    Component: lazy(() => import("./pages/About")),
    preload: () => import("./pages/About"),
  },
  '/contact': {
    Component: lazy(() => import("./pages/Contact")),
    preload: () => import("./pages/Contact"),
  },
  '*': {
    Component: lazy(() => import("./pages/NoPage")),
    preload: () => import("./pages/NoPage"),
  },
}

export function App() {
  useEffect(() => {
    Object.values(pages).forEach(page => page.preload());
  }, []);

  return (   
    <WindowSizeProvider>
      <I18nextProvider i18n={i18next}>
        <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
          <NavBar />
          <Suspense fallback={<Loading />}>
            <Routes>
              {Object.entries(pages).map(([path, { Component }]) => (
                <Route key={path} path={path} element={<Component />} />
              ))}
            </Routes>
          </Suspense>
        </BrowserRouter>
      </I18nextProvider>
    </WindowSizeProvider>
  );
}

export default App;
*/

//biztmás

import { BrowserRouter, Route, Routes  } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import NavBar from "./pages/components/NavBar";
import Home from "./pages/Home";
import LuggageStoragePage from "./pages/LuggageStoragePage";
import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";
import ApartmentList from "./pages/ApartmentList";
import About from "./pages/About";

import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import './i18n';

import { WindowSizeProvider } from './pages/components/WindowSizeContext';
import React, { Suspense } from 'react';
import Loading from './pages/components/Loading';
import './App.css';

import { useLayoutEffect, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';


//jól működik, de nem igazán szeretem látni folyton a scrollozást
function ScrollToTop() {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    //setTimeout(() => window.scrollTo(0, 0), 0);
    setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 0);
  }, [pathname]);

  return null;
}





export function App() {
 // console.log('App rendered');


  return (   
    <WindowSizeProvider>
      <I18nextProvider i18n={i18next}>
        <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
          <NavBar />
          <ScrollToTop />
          <Suspense fallback={<Loading />}>
          <Routes>
        <Route path="/" element={<Home />} />
        <Route path="luggagestorage" element={<LuggageStoragePage />} />
        <Route path="apartmentlist" element={<ApartmentList />} />
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
      </Suspense>
        </BrowserRouter>
      </I18nextProvider>
    </WindowSizeProvider>
  );
}
export default App;



