import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import ReactCountryFlag from "react-country-flag"
import { Dropdown } from 'react-bootstrap';
import './LanguageSwitcher.css';

import { WindowSizeContext } from './WindowSizeContext';


const LanguageSwitcher = ({className}) => {

  const { i18n, t } = useTranslation();
const languages = [
    { code: 'en', flag: 'GB' },
    { code: 'fr', flag: 'FR' },
    { code: 'es', flag: 'ES' },
    { code: 'it', flag: 'IT' },
    { code: 'hu', flag: 'HU' },
  ];
  
const currentLanguage = languages.find(lang => lang.code === i18n.language);
const isDesktop = useContext(WindowSizeContext);


return (
    <Dropdown className={`${className}`} drop='down'>
        <Dropdown.Toggle className="lang-toggle" id="dropdown-basic">

        {!isDesktop && <span style={{ paddingRight: '10px' }}>{t("mobile-lang-toggle-text")}</span>}

            {currentLanguage && <ReactCountryFlag countryCode={currentLanguage.flag} svg />}
        </Dropdown.Toggle>

        <Dropdown.Menu className="my-dropdown-menu">
            {languages.map((language) => (
                <Dropdown.Item
                    key={language.code}
                    onClick={() => i18n.changeLanguage(language.code)}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="flagbox">
                    <ReactCountryFlag countryCode={language.flag} svg />
                    </div>
                    <div style={{ marginLeft: '10px' }}>
                    {language.flag}
                    </div>
                    </div>
                </Dropdown.Item>
            ))}
        </Dropdown.Menu>
    </Dropdown>
);


}

export default LanguageSwitcher;
