import "./css/About.css";
import { useTranslation } from "react-i18next";
//import adaImage from '../resources/Ada.webp';
import { useContext } from 'react';

import { WindowSizeContext } from './components/WindowSizeContext';


const About = () => {

    const { t } = useTranslation();

    const isDesktop = useContext(WindowSizeContext);

    const adaImage = isDesktop ? process.env.REACT_APP_CONTENT_LINK + "/Ada.webp" : process.env.REACT_APP_CONTENT_LINK + "/Ada_small.webp";
    const krisztaImage = isDesktop ? process.env.REACT_APP_CONTENT_LINK + "/Kriszta.webp" : process.env.REACT_APP_CONTENT_LINK + "/Kriszta_small.webp";
    const maciImage = isDesktop ? process.env.REACT_APP_CONTENT_LINK + "/maci02.webp" : process.env.REACT_APP_CONTENT_LINK + "/maci02_small.webp";


    return (
        <div>

            <div className="containerr">
                <div className="container-with-a-pic">
                    <img src={adaImage} alt="Ada" />
                    <p>Adrienn</p>
                </div>
                <div className="firstP">
                    <p>{t("about-p01")}</p>
                </div>
            </div>



            <div className="containerr">
                <div className="firstP">
                <p>{t("about-p02")}</p>
                </div>
                <div className="container-with-a-pic">
                    <img src={krisztaImage} alt="Kriszta" />
                    <p>Krisztina</p>
                </div>
            </div>




            <div className="centered_container">
                <div className="centered_text">
                <p>{t("about-p03")}</p>
                </div>
                <div className="centered_image">
                    <img src={maciImage} alt="maci" />
                </div>
            </div>

            
        </div>
    );
}
export default About;
