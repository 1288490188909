import React, { useMemo } from 'react';
import './FilterComponent.css';

const FilterComponent = ({ minOccupancy, setMinOccupancy, maxOccupancy, setMaxOccupancy, district, city, setCity, setDistrict, itemsPerPage,
    setItemsPerPage, t, resetFilters, memoizedApartments }) => {

    const uniquePeopleCounts = useMemo(() => {
        return [...new Set(memoizedApartments.map(apartment => apartment.people))].sort((a, b) => a - b);
    }, [memoizedApartments]);

    const uniqueDistrictCounts = useMemo(() => {
        return [...new Set(memoizedApartments.map(apartment => apartment.district))].sort((a, b) => a - b);
    }, [memoizedApartments]);


    const uniqueCityCounts = useMemo(() => {
        return [...new Set(memoizedApartments.map(apartment => apartment.city))].sort((a, b) => a - b);
    }, [memoizedApartments]);


    return (
        <div className="filter-container">

            <div className='filter-title-container'>
                <span className='filter-title'>{t("aplist-filter-title")}</span>
            </div>

            <div className="filter-controls">

                <label>
                    {t("aplist-filter-min-capacity")}
                    <select value={minOccupancy} onChange={e => setMinOccupancy(e.target.value)}>
                        <option value="">{t("aplist-filter-default")}</option>
                        {uniquePeopleCounts.map((count, i) => (
                            <option key={"minOccupancy" + i} value={count}>{count}</option>
                        ))}
                    </select>
                </label>

                <label>
                    {t("aplist-filter-max-capacity")}
                    <select value={maxOccupancy} onChange={e => setMaxOccupancy(e.target.value)}>
                        <option value="">{t("aplist-filter-default")}</option>
                        {uniquePeopleCounts.map((count, i) => (
                            <option key={"maxOccupancy" + i} value={count}>{count}</option>
                        ))}
                    </select>
                </label>

                {uniqueDistrictCounts.length > 1 && (
                    <label>
                        {t("aplist-filter-district")}
                        <select value={district} onChange={e => setDistrict(e.target.value)}>
                            <option value="">{t("aplist-filter-default")}</option>
                            {uniqueDistrictCounts.map((count, i) => (
                                <option key={"district" + i} value={count}>{count}</option>
                            ))}
                        </select>
                    </label>
                )}


                {uniqueCityCounts.length > 1 && (
                    <label>
                        {t("aplist-filter-city")}
                        <select value={city} onChange={e => setCity(e.target.value)}>
                            <option value="">{t("aplist-filter-default")}</option>
                            {uniqueCityCounts.map((count, i) => (
                                <option key={"city" + i} value={count}>{count}</option>
                            ))}
                        </select>
                    </label>
                )}


                <label>
                    {t("aplist-filter-items-per-page")}
                    <select value={itemsPerPage} onChange={e => setItemsPerPage(Number(e.target.value))}>
                        <option value="1">1</option>
                        <option value="3">3</option>
                        <option value="5">5</option>
                        <option value="10">10</option>
                    </select>
                </label>
            </div>

            <div className='reset-filter-container'>
                <button onClick={resetFilters}>
                    {t("aplist-filter-clear-filters")}
                </button>
            </div>

        </div>
    );
};

export default FilterComponent;