
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/EN-translation.json";
import translationFR from "./locales/FR-translation.json";
import translationES from "./locales/ES-translation.json";
import translationIT from "./locales/IT-translation.json";
import translationHU from "./locales/HU-translation.json";

const resources = {
  en: {    translation: translationEN,  },
  fr: {    translation: translationFR,  },
  es: {    translation: translationES,  },
  it: {    translation: translationIT,  },
  hu: {    translation: translationHU,  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  react: {
        useSuspense: true
      },       
});

export default i18n;



/* ---->> TÖBB KISEBB NYELVI FÁJLLAL */
/*
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Import your translation files
import translationEN from "./locales/en/translation.json";
import homeEN from "./locales/en/home.json";
import aboutEN from "./locales/en/about.json";

// Add your translation files to the resources
const resources = {
  en: {
    translation: translationEN,
    home: homeEN,
    about: aboutEN
  },
  // Add other languages here...
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true
    },
    ns: ['translation', 'home', 'about'],
    defaultNS: 'translation',
  });

export default i18n;
*/



/* ---->> EGY NAGY NYELVI FÁJL
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/EN-translation.json";
import translationFR from "./locales/FR-translation.json";
import translationES from "./locales/ES-translation.json";
import translationIT from "./locales/IT-translation.json";
import translationHU from "./locales/HU-translation.json";

const resources = {
  en: {    translation: translationEN,  },
  fr: {    translation: translationFR,  },
  es: {    translation: translationES,  },
  it: {    translation: translationIT,  },
  hu: {    translation: translationHU,  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  react: {
        useSuspense: true
      },       
});

export default i18n;
*/