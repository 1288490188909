import { Navbar, NavLink, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from '../../resources/twosis_logo_small.webp';
import './NavBar.css';
import React from 'react';
import { DesktopMenu, ExtraDropMenu } from './DesktopMenus';
import { MobileDropMenu } from './MobileMenus';
import  LanguageSwitcher  from './LanguageSwitcher';

import { useContext } from 'react';
import { WindowSizeContext } from './WindowSizeContext';

const NavBar = () => {
  const isDesktop = useContext(WindowSizeContext);

  console.log("isDesktop", isDesktop);

  return (
    <Container fluid>
      <Navbar fixed="top" className="px-lg-5 px-md-2 navbar-body d-flex">
        {/* Logo */}
        <div className="d-flex align-items-center me-auto" style={{ flexShrink: 0 }}>
          <Navbar.Brand style={{width: '40px'}}>
            <img className="mainLogo" src={logo} alt="logo" style={{ width: '100%'}}/>
          </Navbar.Brand>
          {/* Logo text (desktop view) */}
          <Navbar.Brand className="d-md-block ms-2">
            <NavLink to="/" as={Link}  className="brandname">
            Twosis Apartments
            </NavLink>
          </Navbar.Brand>
        </div>


        {/* Menu items and Dropdown menu */}
        <div className="ml-auto d-flex align-items-center">

        {/* Toggle button */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
          {isDesktop ? <DesktopMenu  /> : null}
          
          <div className="dropmenu">
            {isDesktop ? <ExtraDropMenu desktop={isDesktop} /> : <MobileDropMenu  />}
          </div>
        </div>


        <LanguageSwitcher className="lang-switcher d-none d-md-block hide-at-855px custom-dropdown" />

      </Navbar>
    </Container>
  );


};

export default NavBar;