import { Nav, NavDropdown, NavLink } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import React, { memo } from 'react';
import './DesktopMenus.css';
import { useTranslation } from "react-i18next";


export const DesktopMenu = memo(() => {
    const { t } = useTranslation();

   // console.log("DesktopMenu rendered");

    return (
            <Nav className="mx-1 d-none d-sm-flex ml-auto">
                <NavLink to="/about" as={Link} className="navlink">{t("navmenu-about")}</NavLink>
                <NavLink to="/luggagestorage" as={Link}   className="navlink">{t("navmenu-luggage")}</NavLink>
                <NavLink to="/apartmentlist" as={Link}   className="navlink">{t("navmenu-apartments")}</NavLink>
                <NavLink to="/contact" as={Link}   className="navlink">{t("navmenu-contact")}</NavLink>
            </Nav>
    );
});


export const ExtraDropMenu = memo(({desktop}) => {
    const { t } = useTranslation();
    
const extraButtonWidth = desktop ? '70px' : '125px';

    return (
        <Nav className="justify-content-end">
            <NavDropdown title={
                                <button className="btn btn-primary btn-small" style={{width: extraButtonWidth}}>
                                    {t("navmenu-extra-button-text")}
                                </button>
                                }
                         id="basic-nav-dropdown" 
                         align="end" 
                         className="custom-nav-dropdown"
            >
                <NavDropdown.Item>
                    {t("navmenu-extra-enter-guest")} 
                    <span style={{ marginLeft: '10px' }}><i>(coming soon!)</i></span>
                </NavDropdown.Item>
                <NavDropdown.Item>
                    {t("navmenu-extra-enter-empl")}
                    <span style={{ marginLeft: '10px' }}><i>(coming soon!)</i></span>
                </NavDropdown.Item>
            </NavDropdown>
        </Nav>
    );
}
);
   