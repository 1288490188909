//forwardRef+useImperativeHandle
import React, { useState, useEffect, forwardRef, useImperativeHandle, useCallback  } from "react";
import { useTranslation } from "react-i18next";
import "./GalleryModal.css";

const GalleryModal = forwardRef((props, ref) => {

  const [activeIndex, setActiveIndex] = useState(0);
  const { t } = useTranslation();
   const [isVisible, setIsVisible] = useState(false);




const handleNavigation = useCallback((direction) => {
  setActiveIndex((activeIndex + direction + props.gallery.length) % props.gallery.length);
}, [activeIndex, props.gallery.length]);


const onClose = useCallback(() => {
  setActiveIndex(0);
  setIsVisible(false);
}, [setActiveIndex, setIsVisible]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
       onClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);


  useImperativeHandle(ref, () => ({
    openModal: () => setIsVisible(true),
    closeModal: () => setIsVisible(false),
  }));


   if (!isVisible) return null;



  return (
    <div className="modal-background" key={props.galIndex}>
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>X</button>
        <div className="modal-header"></div>
        <div className="modal-image-container">
          <img src={props.gallery[activeIndex]} alt="img" />
        </div>
        <div className="modal-navigation">
          <button onClick={() => handleNavigation(-1)}>{t("aplist-modal-prev")}</button>
          <span className="modal-navigation-counter">{activeIndex + 1} / {props.gallery.length}</span>
          <button onClick={() => handleNavigation(1)}>{t("aplist-modal-next")}</button>
        </div>
      </div>
    </div>
  );
});

//biztmás
//export default GalleryModal;

//TESZTELNI!!!!!
///kísérleti, hogy minél kevesebb re-renderelés történjen
export default React.memo(GalleryModal);




/*működik. tradicionális út  */
/*
import React, { useState, useEffect, useCallback  } from "react";
import { useTranslation } from "react-i18next";
import "./GalleryModal.css";

const GalleryModal = ({  setOpenModal, gallery  }) => {

    const [activeIndex, setActiveIndex] = useState(0);
    const { t } = useTranslation();


    const handlePrev = () => {
    setActiveIndex((activeIndex - 1 + gallery.length) % gallery.length);
  };

  const handleNext = () => {
    setActiveIndex((activeIndex + 1) % gallery.length);
  };

  const closeModal = useCallback(() => {
    setOpenModal(false)
  }, [setOpenModal]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        closeModal()
      }
    };
  
    document.addEventListener('keydown', handleKeyDown);
  
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setOpenModal, closeModal]);



  console.log('GalleryModal FULLY rendered');

return (
<div className="modal-background">
    <div className="modal-content">

    <button className="modal-close" onClick={closeModal}>X</button>

        <div className="modal-header">

        </div>
        <div className="modal-image-container">
            <img src={gallery[activeIndex]} alt="img" />
        </div>


        <div className="modal-navigation">
                <button onClick={handlePrev}>{t("aplist-modal-prev")}</button>
                <span>{activeIndex + 1} / {gallery.length}</span>
                <button onClick={handleNext}>{t("aplist-modal-next")}</button>
            </div>

    </div>
</div>
);

};
export default GalleryModal;
*/


