
import "./css/Home.css";
import { useTranslation, Trans } from "react-i18next";

const Home = () => {
//console.log('Home rendered');
const { t } = useTranslation();

  return (
    <div className="containerr">
      <h1 className="welcome-text">
        {t("home-welcome01")} <br className="responsive-break" />
        {t("home-welcome02")} <br className="responsive-break" />
        {t("home-welcome03")}
      </h1>

      <p><Trans i18nKey="home-p01" components={{ strong: <strong /> }} /></p>

      <h3 className="list-title">{t("home-list-title")}</h3>
      <ol>
        <li>{t("home-li-1")}</li>
        <li>{t("home-li-2")}</li>
        <li>{t("home-li-3")}</li>
        <li>{t("home-li-4")}</li>
        <li>{t("home-li-5")}</li>
      </ol>

      <p className="centered"><Trans i18nKey="home-p02" components={{ strong: <strong /> }} /></p>

      <p>{t("home-p03")}</p>

      <p className="short-break">{t("home-p04")}</p>

      <p className="outro"><Trans i18nKey="home-p05" components={{ u: <u /> }} /></p>

    </div>
  );

};

export default Home;