import { Nav, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './MobileMenus.css';
import  LanguageSwitcher  from './LanguageSwitcher';
import { useTranslation } from "react-i18next";

import {  ExtraDropMenu } from './DesktopMenus';


export const MobileDropMenu = () => {
   
    const { t } = useTranslation();

    return (
        <Nav className="mobile-custom-nav">
            <Dropdown className="mobile-custom-dropdown">
                <Dropdown.Toggle variant="primary" id="dropdown-basic" className='btn-small'>
                {t("mobiledropmenu-button-text")}
                </Dropdown.Toggle>

                <Dropdown.Menu className="mobile-dropdown-menu mobile-custom-dropdown-menu" align="end">
                    <Dropdown.Item to="/about" as={Link} className="mobile-navlink">
                        {t("navmenu-about")}
                    </Dropdown.Item>
                    <Dropdown.Item to="/luggagestorage" as={Link} className="mobile-navlink">
                        {t("navmenu-luggage")}
                    </Dropdown.Item>
                    <Dropdown.Item to="/apartmentlist" as={Link} className="mobile-navlink">
                        {t("navmenu-apartments")}
                    </Dropdown.Item>
                    <Dropdown.Item to="/contact" as={Link} className="mobile-navlink">
                        {t("navmenu-contact")}
                    </Dropdown.Item>

<div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px', paddingBottom: '10px' }}>
    <ExtraDropMenu desktop={false} />
</div>



                    <LanguageSwitcher className="mobile-lang-switcher mobile-custom-dropdown"  />
                </Dropdown.Menu>
            </Dropdown>
        </Nav>
    );
}